import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { HWWidgets } from '../apis/hyperwallet/types';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '../ngrx';
import { LoggerService } from './logger.service';
import { getCurrentLang } from '../ngrx/global/global.selectors';
import { getHWLocaleFromLocale } from '../utils/LocaleUtils';
import { CustomLocale } from '../enums/language-code.enum';

export enum HyperWalletState {
  Idle,
  Loading,
  Loaded,
  Error,
}

export enum SelectedTransferMethod {
  None,
  BankAccount,
  PayPalAccount,
}

@Injectable()
export class HyperwalletService implements OnDestroy {
  private unsubcriber = new Subject();
  private hyperwallet: Subject<HWWidgets>;
  private currentLang = CustomLocale.EN_US as string;
  private observer = this._transferMethodObserver();
  public hyperwallet$ = new Observable<HWWidgets>();
  public loadState = new Subject<HyperWalletState>();
  public widgetLoading = new Subject<boolean>();
  public selectedTransferMethod$ = new BehaviorSubject(
    SelectedTransferMethod.None,
  );

  public HYPERWALLET_ELEMENT_ID = 'TransferMethodUI';
  public COUNTRY_SELECT_NAME = 'country';
  public CURRENCY_SELECT_NAME = 'currency';
  public COUNTRY_SELECT_QUERY = `#${this.HYPERWALLET_ELEMENT_ID} select[name='${this.COUNTRY_SELECT_NAME}']`;
  public CURRENCY_SELECT_QUERY = `#${this.HYPERWALLET_ELEMENT_ID} select[name='${this.CURRENCY_SELECT_NAME}']`;
  public CURRENCY_VALUE_ID =
    'hw-widget-selection-step-tmc-selection-form-ROUTER-currency_value';
  public CURRENCY_VALUE_QUERY = `#${this.CURRENCY_VALUE_ID}`;
  public BANK_ACCOUNT_TRANSFER_METHOD_QUERY = '#BANK_ACCOUNT';
  public PAYPAL_ACCOUNT_TRANSFER_METHOD_QUERY = '#PAYPAL_ACCOUNT';
  public TRANSFER_METHODS_HEADER_QUERY =
    '#hw-widget-selection-step-tmc-selection-form-addTransferMethodHeader';

  constructor(
    private _store: Store<AppState>,
    private _loggerService: LoggerService,
  ) {
    this.hyperwallet = new Subject<HWWidgets>();
    this.hyperwallet$ = this.hyperwallet.asObservable();
    this.loadState.next(HyperWalletState.Idle);

    this._store
      .pipe(select(getCurrentLang), takeUntil(this.unsubcriber))
      .subscribe(
        (lang: CustomLocale) =>
          (this.currentLang = getHWLocaleFromLocale(lang)),
      );
  }

  injectScript(userId: string) {
    this.loadState.next(HyperWalletState.Loading);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `${environment.hyperwalletUrl}${userId}/${this.currentLang}.v2_4_5.min.js`;
    script.onerror = (error) => {
      this._loggerService.error(`Hyperwallet script load error: ${error}`);
      this.loadState.next(HyperWalletState.Error);
    };
    script.onload = () => {
      this.hyperwallet.next(window.HWWidgets);
      this.loadState.next(HyperWalletState.Loaded);
      window.HWWidgets.events.on('widget:loading:shown', () => {
        this.widgetLoading.next(true);
      });
      window.HWWidgets.events.on('widget:loading:hidden', () => {
        this.widgetLoading.next(false);
      });
      window.HWWidgets.events.on('widget:error:shown', (error: string) => {
        this._loggerService.error(`hyperwallet error: ${error}`);
      });
    };
    document.head.appendChild(script);
  }

  private _transferMethodObserver() {
    const observer = new MutationObserver((mutations) => {
      mutations.find((mutation) => {
        for (let i = 0; i < mutation.addedNodes.length; i += 1) {
          const added = mutation.addedNodes[i] as Element;
          if (added.nodeType !== Node.ELEMENT_NODE) {
            continue;
          }
          if (added.querySelector(this.TRANSFER_METHODS_HEADER_QUERY)) {
            this.selectedTransferMethod$.next(SelectedTransferMethod.None);
            return true;
          }

          if (added.querySelector(this.BANK_ACCOUNT_TRANSFER_METHOD_QUERY)) {
            this.selectedTransferMethod$.next(
              SelectedTransferMethod.BankAccount,
            );
            return true;
          }
          if (added.querySelector(this.PAYPAL_ACCOUNT_TRANSFER_METHOD_QUERY)) {
            this.selectedTransferMethod$.next(
              SelectedTransferMethod.PayPalAccount,
            );
            return true;
          }
        }
        return false;
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return observer;
  }

  ngOnDestroy(): void {
    this.unsubcriber.next(null);
    this.unsubcriber.complete();
    this.observer.disconnect();
  }
}
