import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../ngrx';
import { Store } from '@ngrx/store';
import {
  getAnonymizedUserEmail,
  getCurrentLang,
  getExercise,
  getPartnerId,
} from '../ngrx/global/global.selectors';
import {
  BehaviorSubject,
  filter,
  map,
  mergeMap,
  Subject,
  takeUntil,
  zip,
} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Partner } from '../enums/partner.enum';
import { formatDate, formatPercent } from '@angular/common';
import { getAngularLocalefromLocale } from '../utils/LocaleUtils';
import { formatCurrency } from '@hopper-i18n/formatter';

@Injectable()
export class PartnerI18nService implements OnDestroy {
  private _unsubscriber = new Subject();
  private partnerId$ = this._store.select(getPartnerId);
  private anonymizedEmailAddress$ = this._store.select(getAnonymizedUserEmail);
  private exercise$ = this._store
    .select(getExercise)
    .pipe(filter(Boolean), takeUntil(this._unsubscriber));

  private locale$ = this._store
    .select(getCurrentLang)
    .pipe(map(getAngularLocalefromLocale), takeUntil(this._unsubscriber));

  public showTransferMethodWarning$ = new BehaviorSubject(false);

  constructor(
    private _store: Store<AppState>,
    private _translateService: TranslateService,
  ) {
    this.partnerId$.subscribe((partnerId) => {
      if (partnerId === Partner.VIRGIN_AUSTRALIA) {
        this.showTransferMethodWarning$.next(true);
      }
    });
  }

  private formatFullDate(date: Date, locale: string) {
    return formatDate(date, 'full', locale);
  }

  private formatPercent(amount: number, locale: string) {
    return formatPercent(amount, locale, '1.0-0');
  }

  public payoutAmountContractCurrency$ = zip(this.exercise$, this.locale$).pipe(
    map(([exercise, locale]) =>
      formatCurrency(
        exercise.hopperRefundCurrency,
        locale,
        Number(exercise.cashRefundAllowance),
      ),
    ),
  );

  public pageTitle$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.TITLE_WITHOUT_HTS'
          : 'CFAR_EXERCISE_FLOW.TITLE';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );

  public sendCodeText$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const label =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.CHECK_VERIFICATION_INFO'
          : 'CFAR_EXERCISE_FLOW.CHECK_VERIFICATION_TEXT';
      return this._translateService.stream(label);
    }),
    takeUntil(this._unsubscriber),
  );

  public checkCodeText$ = zip(
    this.partnerId$,
    this.anonymizedEmailAddress$,
  ).pipe(
    mergeMap(([partnerId, anonymizedEmailAddress]) => {
      const label =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.SEND_VERIFICATION_INFO'
          : 'CFAR_EXERCISE_FLOW.SEND_VERIFICATION_TEXT';
      return this._translateService.stream(label, {
        email: anonymizedEmailAddress,
      });
    }),
    takeUntil(this._unsubscriber),
  );

  public deadlineSentence1$ = zip(
    this.partnerId$,
    this.exercise$,
    this.locale$,
  ).pipe(
    mergeMap(([partnerId, exercise, locale]) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.DEADLINE_SUMMARY'
          : 'CFAR_EXERCISE_FLOW.DEADLINE_SENTENCE_1';

      return this._translateService.stream(translationKey, {
        date: this.formatFullDate(exercise.contractExpiryDateTime, locale),
        coverage: this.formatPercent(
          Number(exercise.contractCoveragePercentage) / 100,
          locale,
        ),
      });
    }),
    takeUntil(this._unsubscriber),
  );

  public deadlineWarning$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      if (partnerId === Partner.VIRGIN_AUSTRALIA) {
        return this._translateService.stream(
          'CFAR_EXERCISE_FLOW.DEADLINE_WARNING',
        );
      }
      // This warning is specific to VA atm
      return Promise.resolve(undefined);
    }),
    takeUntil(this._unsubscriber),
  );

  public deadlineSentence2$ = zip(
    this.partnerId$,
    this.payoutAmountContractCurrency$,
  ).pipe(
    mergeMap(([partnerId, amount]) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.DEADLINE_AMOUNT'
          : 'CFAR_EXERCISE_FLOW.DEADLINE_SENTENCE_2';
      return this._translateService.stream(translationKey, {
        amount,
      });
    }),
    takeUntil(this._unsubscriber),
  );

  public refundContinueWarning$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.REFUND_CONTINUE_WARNING'
          : 'CFAR_EXERCISE_FLOW.REFUND_WARNING_MESSAGE';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );

  public refundConfirmWarning$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.REFUND_CONFIRM_WARNING'
          : 'CFAR_EXERCISE_FLOW.REFUND_WARNING_MESSAGE';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );

  public confirmationSubtitle$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.REFUND_AMOUNT'
          : 'CFAR_EXERCISE_FLOW.REFUND_METHOD';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }
}
